<template>
  <gc-dialog
    :show.sync="innerVisible"
    :title="title"
    width="900px"
    @close="resetForm"
    @open="init"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="device-type-ruleForm"
      label-position="top"
      :disabled="formDisabled"
    >
      <div class="split-block">
        <el-form-item label="设备大类" prop="firstCategory" ref="firstCategory">
          <el-select
            v-model="ruleForm.firstCategory"
            placeholder="请选择"
            :disabled="isDisabled"
          >
            <el-option
              v-for="item in firstCategoryOptions"
              :label="item.name"
              :value="item.productCategoryId"
              :key="item.productCategoryId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="设备小类"
          prop="secondCategory"
          ref="secondCategory"
        >
          <el-select
            v-model="ruleForm.secondCategory"
            placeholder="请选择"
            :disabled="isDisabled"
          >
            <el-option
              v-for="item in secondCategoryOptions"
              :label="item.name"
              :value="item.productCategoryId"
              :key="item.productCategoryId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="设备类型名称"
          prop="deviceTypeName"
          ref="deviceTypeName"
        >
          <el-input
            v-model="ruleForm.deviceTypeName"
            placeholder="请填写"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="厂商名称"
          prop="manufacturerId"
          ref="manufacturerId"
        >
          <el-select
            v-model="ruleForm.manufacturerId"
            placeholder="请选择"
            :disabled="isDisabled"
          >
            <el-option
              v-for="item in manufacturerOptions"
              :label="item.manufacturerName"
              :value="item.manufacturerId"
              :key="item.manufacturerId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品类型" prop="productId" ref="productId">
          <el-select
            v-model="ruleForm.productId"
            placeholder="请选择"
            :disabled="isDisabled"
          >
            <el-option
              v-for="item in productOptions"
              :label="item.name"
              :value="item.productId"
              :key="item.productId"
            ></el-option>
          </el-select>
        </el-form-item>
        <!--产品类型联动字段 -->
        <el-form-item
          v-for="(item, index) in ruleForm.fieldCollect"
          :label="item.name"
          :prop="`fieldCollect[${index}].defaultValue`"
          :ref="`fieldCollect[${index}].defaultValue`"
          :key="item.attributeKey"
          :rules="item.rules"
        >
          <!-- 输入框 -->
          <el-input
            v-if="item.valueType == 0"
            v-model="item.defaultValue"
            :placeholder="item.isLink == 1 ? '自动填充' : '请填写'"
            :disabled="item.isLink == 1"
          >
            <span slot="suffix" v-if="item.unitname">{{ item.unitname }}</span>
          </el-input>
          <!-- 时间选择器 -->
          <el-date-picker
            v-else-if="item.valueType == 1"
            type="date"
            v-model="item.defaultValue"
            placeholder="请选择日期"
          ></el-date-picker>
          <!-- 下拉 -->
          <el-select
            v-else
            v-model="item.defaultValue"
            :placeholder="item.isLink == 1 ? '自动填充' : '请选择'"
            :disabled="item.isLink == 1"
          >
            <el-option
              v-for="(value, name) in item.interactiveTranslationDes"
              :label="value"
              :value="name + ''"
              :key="name"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <template #footer>
      <div class="footer">
        <el-button circle @click="resetForm"> 取 消 </el-button>
        <el-button
          circle
          type="primary"
          v-if="innerFlag == 'detail'"
          @click="innerFlag = 'edit'"
          >修 改
        </el-button>
        <el-button
          circle
          type="primary"
          v-else
          @click="submitForm"
          :loading="loading"
        >
          提 交
        </el-button>
      </div>
    </template>
  </gc-dialog>
</template>

<script>
import { ruleRequired, ruleMaxLength } from "@/utils/rules.js";
import {
  apiAddDeviceType,
  apiGetFirstCategory,
  apiGetSecondCategory,
  apiGetManufacturer,
  apiGetProduct,
  apiDtAttribute,
  apiDeviceTypeDetail,
  apiEditDeviceType,
} from "@/api/type.js";
import { isBlank, isString } from "@/utils/validate.js";
import { handleParams } from "@/utils/index.js";

export default {
  name: "deviceTypeDialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    flag: {
      type: String,
      default: "add",
    },
    deviceTypeId: {},
  },
  data() {
    return {
      ruleForm: {
        firstCategory: "", //设备大类
        secondCategory: "", //设备小类
        deviceTypeName: "", //设备类型名称
        manufacturerId: "", //厂商Id
        productId: "", //产品类型Id
        fieldCollect: [], //联动字段集合
      },
      rules: {
        firstCategory: [ruleRequired("请选择设备大类")],
        secondCategory: [ruleRequired("请选择设备小类")],
        deviceTypeName: [ruleRequired("请输入设备类型名称"), ruleMaxLength(32)],
        manufacturerId: [ruleRequired("请选择厂商")],
        productId: [ruleRequired("请选择产品类型")],
      },
      firstCategoryOptions: [], //设备大类下拉
      secondCategoryOptions: [], //设备小类下拉
      manufacturerOptions: [], //厂商下拉
      productOptions: [], //产品类型下拉
      initFlag: false, //是否为初次回填
      loading: false,
    };
  },
  computed: {
    innerVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    innerFlag: {
      get() {
        return this.flag;
      },
      set(val) {
        this.$emit("update:flag", val);
      },
    },
    title() {
      return this.innerFlag == "detail"
        ? "设备类型详情"
        : this.innerFlag == "edit"
        ? "设备类型修改"
        : "设备类型新增";
    },
    // 表单是否可编辑
    formDisabled() {
      return this.innerFlag == "detail";
    },
    // 设备大类、设备分类、厂商名称、产品类型-不可修改
    isDisabled() {
      return this.innerFlag == "edit";
    },
  },
  watch: {
    // 设备大类-设备小类
    "ruleForm.firstCategory"(newVal) {
      if (!isBlank(newVal)) {
        apiGetSecondCategory({
          productCategoryId: newVal,
        }).then((res = []) => {
          this.secondCategoryOptions = res.list || [];
          let secondCategoryItem = this.secondCategoryOptions.find(
            (item) => item.productCategoryId == this.ruleForm.secondCategory
          );
          // 当前设备大类中不包括所填的设备小类，则清空
          if (!secondCategoryItem) {
            this.ruleForm.secondCategory = "";
          }
        });
      }
    },
    // 产品类型-设备类型属性集合
    "ruleForm.productId"(newVal, oldVal) {
      // 初次回填
      if (this.initFlag) {
        this.initFlag = false;
      } else {
        if (!isBlank(newVal)) {
          this.ruleForm.fieldCollect = [];
          this.getDtAttribute(newVal);
        }
      }
    },
  },
  methods: {
    // 初始化
    async init() {
      Promise.allSettled([
        apiGetFirstCategory(),
        apiGetManufacturer({ size: 999 }),
        apiGetProduct(),
      ]).then((res) => {
        res.map((item, index) => {
          switch (index) {
            case 0:
              this.firstCategoryOptions = item.value.list || [];
              break;
            case 1:
              this.manufacturerOptions = item.value.records || [];
              break;
            case 2:
              this.productOptions = item.value.list || [];
              break;
          }
        });
        // 详情/修改
        if (["detail", "edit"].includes(this.innerFlag)) {
          apiDeviceTypeDetail({
            deviceTypeId: this.deviceTypeId,
          }).then((res = {}) => {
            this.initFlag = true;
            let { productAttributeInfo = "", ...args } = res || {};
            Object.keys(args).map((key) => {
              if (Object.prototype.hasOwnProperty.call(this.ruleForm, key)) {
                this.ruleForm[key] = res[key];
              }
            });
            this.getDtAttribute(res.productId).then(() => {
              if (!isBlank(productAttributeInfo)) {
                productAttributeInfo = JSON.parse(productAttributeInfo);
                this.ruleForm.fieldCollect.map((item) => {
                  if (
                    Object.prototype.hasOwnProperty.call(
                      productAttributeInfo,
                      item.attributeKey
                    )
                  ) {
                    item.defaultValue = productAttributeInfo[item.attributeKey];
                  }
                });
              }
            });
          });
        }
      });
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid, object) => {
        if (valid) {
          this.loading = true;
          let { fieldCollect, ...args } = this.ruleForm;
          let configInfo = {};
          fieldCollect.length > 0 &&
            fieldCollect.forEach((item) => {
              configInfo[item.attributeKey] = item.defaultValue;
            });
          let params = Object.assign(handleParams(args), {
            configInfo: handleParams(configInfo),
          });
          this.handleRequest(params)
            .then(() => {
              this.resetForm();
              this.$message.success(
                this.innerFlag == "add"
                  ? "设备类型新增成功"
                  : "设备类型修改成功"
              );
              this.$emit("update-list");
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          for (let i in object) {
            let dom = this.$refs[i];
            if (Object.prototype.toString.call(dom) !== "[object Object]") {
              //这里是针对遍历的情况（多个输入框），取值为数组
              dom = dom[0];
            }
            dom.$el.scrollIntoView({
              //滚动到指定节点
              block: "center", //值有start,center,end，nearest，当前显示在视图区域中间
              behavior: "smooth", //值有auto、instant,smooth，缓动动画（当前是慢速的）
            });
            break;
          }
        }
      });
    },
    resetForm() {
      this.ruleForm = this.$options.data().ruleForm;
      this.$refs.ruleForm.resetFields();
      this.innerVisible = false;
    },
    // 设备类型属性集合
    getDtAttribute(productId) {
      return new Promise((resolve) => {
        apiDtAttribute({
          productId,
        }).then((res = []) => {
          let middleArr = res || [];
          // 校验条件
          middleArr.map((item) => {
            item.rules = [];
            // 必填
            if (item.optional == 1) {
              item.rules.push(ruleRequired("请输入" + item.name));
            }
            // 长度
            if (!isBlank(item.lengthCheck)) {
              item.rules.push(ruleMaxLength(item.lengthCheck));
            }
            // 正则
            if (!isBlank(item.inputCheck)) {
              item.rules.push({
                pattern: new RegExp(item.inputCheck.reg),
                message: item.inputCheck.regMsg,
                trigger: "",
              });
            }
          });
          this.ruleForm.fieldCollect = middleArr;
          resolve(middleArr);
        });
      });
    },
    // 接口请求
    handleRequest(obj) {
      if (this.innerFlag == "add") {
        //新增
        return apiAddDeviceType(obj);
      } else if (this.innerFlag == "edit") {
        obj["deviceTypeId"] = this.deviceTypeId;
        // 修改
        return apiEditDeviceType(obj);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.device-type-ruleForm {
  .title {
    width: 100%;
    color: #ababab;
  }
  .split-block {
    display: flex;
    flex-wrap: wrap;
  }
  .split-line {
    margin: 20px 0;
    border-bottom: 1px dashed #cccccc;
  }
  ::v-deep {
    .el-form-item {
      padding-top: 20px;
      margin-bottom: 0;
      margin-right: 30px;
      width: calc(calc(100% - 60px) / 3);
    }
    .el-form-item:nth-of-type(3n) {
      margin-right: 0;
    }
    .el-form-item__label {
      line-height: 21px;
      height: auto;
      font-weight: 600;
      color: #222;
    }
  }
}
</style>
